<template>
<div class="wrapper">
  <!-- 打扫卫生记录添加/修改 -->
  <h4>{{ titleName }}打扫卫生</h4>
  <el-form :model="form" ref="form" :rules="rules" label-width="120px">
    <el-row :gutter="20">
      <!-- <el-col :span="8">
        <el-form-item  label="服务对象" prop="serviceOldPersonId">
          <el-select v-model="form.serviceOldPersonId" 
          @change="serviceOldPersonChange"
          placeholder="请选择服务对象" style="width:100%;" :disabled="isDetail">
            <el-option
              v-for="item in personList"
              :key="item.id"
              :label="item.personName"
              :value="item.id">
            </el-option>
          </el-select>
        </el-form-item>
      </el-col> -->
      <el-col :span="8">
        <el-form-item  label="服务机构" prop="serviceOrgId">
          <el-select v-model="form.serviceOrgId" placeholder="请选择服务公司" style="width:100%;" :disabled="isDetail">
            <el-option
              v-for="item in orgList"
              :key="item.id"
              :label="item.name"
              :value="item.id">
            </el-option>
          </el-select>
        </el-form-item>
      </el-col>
      <el-col :span="8">
        <el-form-item  label="消杀物品" prop="disinfectItems">
          <el-input v-model="form.disinfectItems" placeholder="请输入消杀物品" auto-complete="off" :disabled="isDetail"></el-input>
        </el-form-item>
      </el-col>
            <el-col :span="8">
        <el-form-item  label="消杀区域" prop="disinfectAreas">
          <el-input v-model="form.disinfectAreas" placeholder="请输入消杀区域" auto-complete="off" :disabled="isDetail"></el-input>
        </el-form-item>
      </el-col>
    </el-row>

    <el-row :gutter="20">

            <el-col :span="8">
        <el-form-item  label="工作人员" prop="serviceWorkPersonId">
          <el-select v-model="form.serviceWorkPersonId" placeholder="请选择工作人员" style="width:100%;" :disabled="isDetail">
            <el-option
              v-for="item in servePersonList"
              :key="item.id"
              :label="item.name"
              :value="item.id">
            </el-option>
          </el-select>
        </el-form-item>
      </el-col>
      <el-col :span="8">
        <el-form-item  label="服务项目" prop="serviceItemName">
          <el-input v-model="form.serviceItemName" placeholder="请输入服务项目" auto-complete="off" :disabled="isDetail"></el-input>
        </el-form-item>
      </el-col>

      <el-col :span="8">
        <el-form-item  label="状态" prop="status">
          <el-select v-model="form.status" placeholder="请选择状态" style="width:100%;" :disabled="isDetail">

            <el-option
              v-for="item in statusList"
              :key="item.name"
              :label="item.name"
              :value="item.name">
            </el-option>
          </el-select>
        </el-form-item>
      </el-col>
    </el-row>

  

    <el-row :gutter="20">
      <el-col :span="8">

        <el-form-item  label="消杀时间" prop="serviceTime" >
          <el-date-picker
            v-model="form.serviceTime"
            type="datetime"
            placeholder="选择日期时间" style="width:100%;"
            format="yyyy-MM-dd HH:mm:ss"
            value-format="yyyy-MM-dd HH:mm:ss"
            :disabled="isDetail"
           >
          </el-date-picker>
        </el-form-item>
      </el-col>
    </el-row>
  </el-form>
  <div class="m-footer">
    <el-button type="primary" icon="fa fa-check" @click="submitEvent('form')" v-if="!isDetail">保存</el-button>
    <el-button icon="fa fa-undo" @click="$router.go(-1)">返回</el-button>
  </div>
</div>
</template>

<script>
import {
  mapActions
} from "vuex";
import UploadImg from '@/components/UploadImg.vue'
import api from '@/store/API/api'
import axios from "axios";

export default {
  name: 'AddOrEdit',
  components: {
    UploadImg
  },
  data() {
    var validTel = (rule, value, callback) => {
      if (value && !this.$regex.telRegEx(this.form.tel)) {
        callback(new Error('联系方式格式不正确'));
      } else {
        callback();
      }
    };
    return {
      isDetail:false,
      titleName: '',
      form: {
      },
      cityList:[],
      provinceList:[],
      serveTime: [],
      beforeImgsList: [],
      afterImgsList: [],
      tablePage: {
        totalResult: 0,
        currentPage: 0,
        pageSize: 10,
        align: "left",
        pageSizes: [10, 20, 50, 100, 200, 500, 1000],
        perfect: true
      },
      rules: {
          serviceItemName: [
            { required: true, message: '请选择服务机构', trigger: 'blur' },
          ],
          disinfectItems: [
            { required: true, message: '请输入消毒物品', trigger: 'blur' },
          ],
          disinfectAreas: [
            { required: true, message: '请输入消杀区域', trigger: 'blur' },
          ],
           serviceWorkPersonId: [
            { required: true, message: '请选择工作人员', trigger: 'blur' },
          ],
          serviceItemName: [
            { required: true, message: '请输入服务项目', trigger: 'blur' },
          ],
          status: [
            { required: true, message: '请输入状态', trigger: 'blur' },
          ],
          serviceTime: [
            { required: true, message: '请选择消杀时间', trigger: 'blur' },
          ],
      },

      statusList:[
        {name:'已完成'},
        {name:'服务中'},
        {name:'待服务'},
        {name:'已取消'},
      ],
      areaList: [],
      streetList: [],
      personList: [],
      orgList: [],
      servePersonList: [],
      map: null,
    }
  },
  computed: {
    routeType() {
      return this.$route.params.type
    }
  },
  created() {
    this.initView()
    // this.getProvincesList()
    // this.initMap()
  },
  methods: {
    ...mapActions([
      "getHomePersonListServe",
      'detailHomeOldPersonServe',
      "getServeOrgsList1",
      "getAllPageListServe",
      "detailServeRecordsServe",
      'addcleanList',
      'updateCleanList',
      'deleteClean',
      'queryCleanList',
      'cleanDetail'
    ]),
   
    initView() {
      this.getPersonList()
      this.getOrgList()
      this.getServePersonList()
      // this.getAreaList('西安市')
      if (this.routeType == 'add') {
        this.titleName = '添加消杀记录'
      }else if(this.routeType == 'xiangqing'){
        this.isDetail = true
        this.titleName = '消杀记录详情'
        this.getServeRecordsDetail()
      }else {
        this.titleName = '修改消杀记录'
        //这里调用请求编辑时的详情接口渲染form表单数据
        this.getServeRecordsDetail()
        
      }
    },
    //查服务记录详情
    getServeRecordsDetail() {
      let params = {
        id: this.$route.params.id,
      }
      this.cleanDetail(params).then((res) => {
        if (res.code == 200) {
          console.log(res.data);
        // this.form.serviceTime = (Math.floor(this.$tools.timeDifference(this.form.serviceBeginTime,this.form.serviceEndTime)/60) + "小时" + (this.$tools.timeDifference(this.form.serviceBeginTime,this.form.serviceEndTime)%60) + "分" );
       
          this.form = {
            // id: this.$route.params.id,
            ...res.data,
          }
        }
      })
    },
    // 获取服务对象
    getPersonList() {
      let params = {
        current: 1,
				size: 99,
        param: {}
      }
      this.getHomePersonListServe(params).then(res => {
        // console.log('aaaaaaaaaaaaaaaaa',typeof(res));
        if (res.code == 200) {
          // console.log(res.data.records,'res.data.records');
          this.personList = res.data.records
        }
      })
    },
    serviceOldPersonChange(val) {
      this.getPersonDetail(val)
    },
    // 人员详情
    getPersonDetail(val) {
      let params = {
        id: val
      }
      this.detailHomeOldPersonServe(params).then((res) => {
        if (res.code == 200) {
          this.form.tel = res.data.contract
        }
      })
    },
    // 获取服务企业
    getOrgList() {
      let params = {
        current: 1,
				size: 99,
        param: {}
      }
      this.getServeOrgsList1(params).then(res => {
        console.log(res);
        if (res.code == 200) {
          this.orgList = res.data;
        }
      })
    },
    // 获取服务员工
    getServePersonList() {
      let params = {
        current: 1,
				size: 99999,
        param: {
          "contTel": "",
          "jobTitle": "",
          "name": ""
        }
      }
      this.getAllPageListServe(params).then(res => {
        if (res.code == 200) {
          this.servePersonList = res.data.records;
        }
      })
    },
    submitEvent(formName) { 
      this.$refs[formName].validate((valid) => {
        if (valid) {
          let params =this.form
          // this.form.serviceTime = (Math.floor(this.form.serviceTime/60) + "小时" +(this.form.serviceTime%60) + "分" )
          if( this.routeType == 'add'){
            this.addcleanList(params).then(res => {
              if (res.code == 200) {
                this.$XModal.message({
                  message:  '添加成功' ,
                  status: "success"
                });
                this.$router.go(-1)
              }else{
                this.$message.error(res.msg);
              }
              this.loading = false;
            })
          }else{
            this.updateCleanList(params).then(res => {
              if (res.code == 200) {
                this.$XModal.message({
                  message:  '修改成功' ,
                  status: "success"
                });
                this.$router.go(-1)
              }else{
                this.$message.error(res.msg);
              }
              this.loading = false;
            })
          }

        } else {
          return false;
        }
      });
      
    }
  }
}
</script>

<style lang="scss" scoped>
.wrapper {
  padding: 10px;
  height: 100%;
  overflow: height;
  display: flex;
  flex-direction: column;
  h4 {
    height: 40px;
    line-height: 40px;
    color: #333;
    padding-left: 20px;
    margin-bottom: 20px;
  }
  .el-form{
    padding: 10px;
    flex: 1;
    overflow: auto;
  }
  .vxe-form {
    padding: 25px 0;
    overflow: auto;
  }
  .my-dropdown1 {
      height: 200px;
      overflow: auto;
      border-radius: 4px;
      background-color: #fff;
      border: 1px solid #dcdfe6;
	  padding: 0 8px;
      li {
        height: 32px;
        line-height: 32px;
        cursor: pointer;
      }
    }
}
</style>
